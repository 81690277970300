// Hero Section
.hero {
  //   padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  line-height: 1.5;
  color: var(--fifth);
  &__content {
    width: 50%;
  }
}
