:root {
  --font-body: Arial, sans-serif;
}

:root {
  --primary: rgb(5, 184, 115);
  --title: hsl(147, 56%, 31%);
  --border: hsl(88, 50%, 61%);
  --secondary: hsl(217, 18%, 14%);
  --third: hsl(217, 9%, 52%);
  --forth: hsl(0, 0%, 73%);
  --fifth: hsl(0, 0%, 100%);
  --neutral-200: hsl(0, 0%, 96%);
  --gradient: hsl(141, 20%, 34%);
  --text-primary: hsl(147, 56%, 31%);
  --footer-bg: hsl(216, 23%, 83%);
  --container-bg-color: #333;
  --left-bg-color: rgba(5, 184, 115, 0.3);
  --left-button-hover-color: rgba(5, 184, 115, 0.3);
  --right-bg-color: rgba(43, 43, 43, 0.8);
  --right-button-hover-color: rgba(92, 92, 92, 0.3);
  --hover-width: 75%;
  --other-width: 25%;
  --speed: 1000ms;
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --red: #ff0000;
}

:root {
  --screen-width: 1440px;
}

html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul,
ol,
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  height: 100%;
}

ol.ordered-list,
ul.unordered-list {
  padding-inline-start: 2rem;
}

hr {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(0.25turn, var(--fifth), var(--primary), var(--fifth));
  border-image-slice: 1;
}

section {
  line-height: 1.6;
  font-size: 1.5rem;
}

.container {
  margin: 0 auto;
  padding: 2rem 3rem;
}

.lead {
  font-size: 2rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 100px;
  color: var(--fifth);
  border: 2px solid var(--border);
  transition: 0.5s;
}
.btn:hover {
  background: var(--fifth);
  color: var(--primary);
  cursor: pointer;
}

.btn-primary {
  background: var(--primary);
  color: var(--fifth);
}

.btn-block {
  display: inline-block;
  margin-bottom: 1rem;
}

.fade-in,
.from-left,
.from-right {
  opacity: 0;
}

.fade-in.appear,
.from-left.appear,
.from-right.appear {
  opacity: 1;
}

.fade-in {
  transition: opacity 250ms ease-in;
}

.from-left {
  transform: translateX(-50%);
}

.from-right {
  transform: translateX(50%);
}

.from-left,
.from-right {
  transition: transform 400ms ease-in;
}

.from-left.appear,
.from-right.appear {
  transform: translateX(0);
}

.home-section {
  padding: calc(122px + 2rem) 3rem 3rem 3rem;
}

.top-section {
  padding: var(--spacer, 122px) 3rem 3rem 3rem;
}

.flex-items {
  display: flex;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}

.column {
  flex-direction: column;
  flex: 1;
}

.flex-reverse {
  flex-direction: row-reverse;
}

.grid-items {
  --grid-columns: 3;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
}

.grid-temp-area {
  grid-template-areas: "content image" "content image";
  grid-template-columns: 50% 1fr;
}

.grid-col {
  text-align: center;
}
.grid-col img {
  max-width: 50%;
}

.name {
  font-size: 2rem;
}

.content {
  font-size: 1.25rem;
  line-height: 1.5;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 1.5rem 0;
}

.py-3 {
  padding: 2rem 0;
}

.parallax {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /*
        background-attachment: fixed = parallax
        background-attachment: scroll = normal
    */
  background-attachment: fixed;
}

.parallax {
  background-image: url("../../../img/dmi_header.jpg");
  min-height: 100vh;
  display: block;
}

.heading-1 {
  font-size: 3rem;
}

.product__content {
  grid-area: content;
}
.product__content h2 {
  margin: 0;
}
.product__content p {
  margin-top: 0.5rem;
}
.product__image {
  grid-area: image;
}

.bg-neutral-400 {
  background-color: var(--forth);
}

.bg-neutral-200 {
  background-color: var(--neutral-200);
}

.bg-primary-300 {
  background-color: var(--primary);
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

h1,
h2,
h3 {
  margin-top: 0;
}

.text-primary {
  color: var(--text-primary);
}

.text-center {
  text-align: center;
}

.text-light {
  color: var(--fifth);
}

.fw-bold {
  font-weight: 700;
}

.container {
  padding: 0 1.5rem;
  margin: 0 auto;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0 3rem;
    transform: translate(0, 0);
  }
}

.section {
  padding-block-start: 122px;
}

.header {
  position: fixed;
  width: 100%;
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, var(--fifth), var(--primary), var(--gradient));
  border-bottom: 3px solid var(--border);
  z-index: 10;
}
.header::before {
  content: "";
  position: absolute;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  background: var(--primary);
}
.header nav .main-nav {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.header nav .main-nav li {
  display: inline-block;
}
.header nav .main-nav li:hover .nav-link__dropdown {
  display: block;
  position: absolute;
  max-width: 30ch;
  list-style: none;
  border-radius: 10px;
  z-index: 100;
}
.header nav .main-nav .nav-link {
  display: block;
  color: var(--fifth);
  padding: 0.75rem 1rem;
  transition: all 100ms cubic-bezier(0.77, 0, 0.175, 1);
  font-size: 1.25rem;
  border: 1px solid transparent;
  text-align: center;
}
.header nav .main-nav .nav-link.active, .header nav .main-nav .nav-link:hover {
  background: var(--primary);
  color: var(--fifth);
  border-radius: 100px;
  border: 1px solid var(--border);
  box-shadow: inset -5px -5px 10px rgba(255, 255, 255, 0.2), inset 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.header nav .main-nav .nav-link__dropdown {
  display: none;
  position: absolute;
  min-width: 35ch;
  height: auto;
  border-radius: 0.625rem;
  z-index: 100;
  background: var(--fifth);
}
.header nav .main-nav .nav-link__dropdown li {
  display: block;
  margin-block: 0.5rem;
  border-radius: 0.625rem;
}
.header nav .main-nav .nav-link__dropdown-link {
  display: block;
  padding: 0.5rem;
  text-align: center;
  color: var(--primary);
  border: 1px transparent solid;
}
.header nav .main-nav .nav-link__dropdown-link:hover {
  background: var(--primary);
  color: var(--fifth);
  border-radius: 100px;
  border: 1px solid var(--border);
  box-shadow: inset -5px -5px 10px rgba(255, 255, 255, 0.2), inset 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.section-home {
  background: url("../../../img/dmi_header.jpg") no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--fifth);
  text-align: center;
}
.section-home h1 {
  font-size: 3.5rem;
}
.section-home .lead {
  font-style: italic;
  font-size: 2rem;
}
.section-home video {
  border-radius: 10px;
  max-width: 600px;
  margin-bottom: 2.5rem;
}

.hero {
  background: url("../../../img/products-hero-lg.jpg") no-repeat center center/cover;
}

.hero-short {
  align-items: center;
  background: url(../../../img/dmi_header.jpg) no-repeat center 30%/cover;
  height: 27.5rem;
}

.section-ourstory {
  padding: 2rem 3rem;
}
.section-ourstory h2 {
  font-size: 2.5rem;
  text-align: center;
}
.section-ourstory .bio-container {
  width: 1280px;
  margin: 0 auto;
  padding: 2rem 0 2rem;
  display: flex;
  justify-content: space-between;
}
.section-ourstory .bio-container .bio-wrapper {
  text-align: center;
}
.section-ourstory .bio-container img {
  max-width: 308px;
}
.section-ourstory .bio-container .bio-content {
  font-weight: bold;
}
.section-ourstory .bio-container .bio-title {
  color: var(--title);
  font-size: 2rem;
}
.section-ourstory .container-ourstory {
  font-size: 1.5rem;
  line-height: 1.6;
}

.section-header {
  position: relative;
}
.section-header h1 {
  font-size: 4rem;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.section-partners {
  position: relative;
  width: 100%;
  height: 100vh;
  background: var(--fifth) url(../../../img/life-carriers.jpg) no-repeat center center/cover;
}
.section-partners h1 {
  font-size: 4rem;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.section-partners .button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 60%;
  height: 2.5rem;
  width: 15rem;
  text-align: center;
  color: #fff;
  border: #fff solid 0.2rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transform: translateX(-50%);
}
@media (max-width: 800px) {
  .section-partners h1 {
    font-size: 2rem;
  }
  .section-partners .button {
    width: 12rem;
  }
}
@media (max-height: 700px) {
  .section-partners .button {
    top: 70%;
  }
}

.partner-hero {
  padding: calc(122px + 2rem) 3rem 3rem 3rem;
}
.partner-hero h1,
.partner-hero p {
  color: #fff;
}
.partner-hero h1 {
  font-size: 3.5rem;
}

.split .left .button:hover {
  background-color: var(--left-bg-color);
  border-color: var(--left-button-hover-color);
}

.split .right .button:hover {
  background-color: var(--right-bg-color);
  border-color: var(--right-button-hover-color);
}

.split {
  position: absolute;
  top: 102;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.split.left {
  left: 0;
  background-size: cover;
}

.split.left:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--left-bg-color);
}

.split.right {
  right: 0;
  background-size: cover;
}

.split.right:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--right-bg-color);
}

.split.left,
.split.right,
.split.left:before,
.split.right:before {
  transition: 1000ms all ease-in-out;
}

.hover-left .left {
  width: 75%;
}

.hover-left .right {
  width: 25%;
}

.hover-left .right:before {
  z-index: 1;
}

.hover-right .right {
  width: 75%;
}

.hover-right .left {
  width: 25%;
}

.hover-right .left:before {
  z-index: 1;
}

.section-partner {
  margin: 0 auto;
  padding: 2rem 3rem;
}

.section-partner h2 {
  text-align: center;
  color: var(--primary);
  font-size: 2rem;
}

.section-partner-photos {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2px;
  width: 100%;
}
.section-partner-photos .img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8.75rem;
  width: 17.5rem;
  padding: 1rem;
}
.section-partner-photos .img-wrapper:hover {
  border: 2px solid var(--primary);
  border-radius: 1.75rem;
}
.section-partner-photos .img-wrapper img {
  max-width: 100%;
  height: auto;
  transform: scale(0.8);
  transition: transform 250ms ease-in;
}
.section-partner-photos .img-wrapper img:hover {
  transform: scale(1);
}

.section-team {
  background: url("../../../img/dmi_header.jpg") no-repeat center 30%/cover;
  height: 27.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-profiles {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-profiles p {
  font-size: 1.25rem;
  line-height: 1.6;
}

.profile-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
  width: 100%;
  column-gap: 1rem;
}
.profile-wrapper.reverse {
  flex-direction: row-reverse;
  background: linear-gradient(90deg, var(--fifth), var(--primary));
}
.profile-wrapper img {
  max-width: 40%;
  height: auto;
}

.name-wrapper {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-weight: bold;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.5rem;
}

form {
  width: 100%;
}

label {
  margin-bottom: 0.25rem;
}

input[type=text],
textarea {
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: 100%;
  border: 1px solid --secondary;
}
input[type=text]:focus,
textarea:focus {
  outline-color: var(--primary);
}

.option-group {
  margin-bottom: 0.25rem;
}

.red-text {
  color: var(--red);
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  line-height: 1.5;
  color: var(--fifth);
}
.hero__content {
  width: 50%;
}

footer {
  font-size: 1.125rem;
  background: var(--footer-bg);
  background: linear-gradient(90deg, var(--fifth), var(--footer-bg));
  padding: 2rem 3rem 1rem;
  border-top: 3px solid var(--border);
}
footer a,
footer a:visited,
footer a:active {
  color: var(--secondary);
  padding: 1rem;
}
footer a.active,
footer a:hover {
  color: var(--primary);
}
footer .footer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
footer .footer-container .footer-column:nth-child(1) {
  width: 37%;
}
footer .footer-container .footer-column:nth-child(2) {
  width: 38%;
}
footer .footer-container .group-wrapper {
  margin-bottom: 1rem;
}
footer .footer-nav {
  text-align: center;
}
footer .footer-nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
footer .footer-copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
}