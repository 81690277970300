footer {
  font-size: 1.125rem;
  background: var(--footer-bg);
  background: linear-gradient(90deg, var(--fifth), var(--footer-bg));
  padding: 2rem 3rem 1rem;
  border-top: 3px solid var(--border);

  a,
  a:visited,
  a:active {
    color: var(--secondary);
    padding: 1rem;
  }
  a.active,
  a:hover {
    color: var(--primary);
  }

  .footer-container {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .footer-column:nth-child(1) {
      width: 37%;
    }
    .footer-column:nth-child(2) {
      width: 38%;
    }

    .group-wrapper {
      margin-bottom: 1rem;
    }
  }

  .footer-nav {
    text-align: center;
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
  }

  .footer-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
  }
}
