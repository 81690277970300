.product {
  &__content {
    grid-area: content;

    & h2 {
      margin: 0;
    }
    & p {
      margin-top: 0.5rem;
    }
  }

  &__image {
    grid-area: image;
  }
}
