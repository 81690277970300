.bg-neutral-400 {
  background-color: var(--forth);
}

.bg-neutral-200 {
  background-color: var(--neutral-200);
}

.bg-primary-300 {
  background-color: var(--primary);
}
