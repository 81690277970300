// Home Page

.section-home {
  background: url("../../../img/dmi_header.jpg") no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--fifth);
  text-align: center;

  h1 {
    font-size: 3.5rem;
  }

  .lead {
    font-style: italic;
    font-size: 2rem;
  }

  video {
    border-radius: 10px;
    max-width: 600px;
    margin-bottom: 2.5rem;
  }
}

.hero {
  background: url("../../../img/products-hero-lg.jpg") no-repeat center
    center/cover;
}

.hero-short {
  align-items: center;
  background: url(../../../img/dmi_header.jpg) no-repeat center 30% / cover;
  height: 27.5rem;
}

// Our Story
.section-ourstory {
  padding: 2rem 3rem;

  h2 {
    font-size: 2.5rem;
    text-align: center;
  }

  .bio-container {
    width: 1280px;
    margin: 0 auto;
    padding: 2rem 0 2rem;
    display: flex;
    justify-content: space-between;
    // border: 2px solid transparent 0 2px solid transparent;
    // border-image: linear-gradient(
    //   0.25turn,
    //   var(--fifth),
    //   var(--primary),
    //   var(--fifth)
    // );
    // border-image-slice: 1;

    .bio-wrapper {
      text-align: center;
    }

    & img {
      max-width: 308px;
    }
    .bio-content {
      font-weight: bold;
    }
    .bio-title {
      color: var(--title);
      font-size: 2rem;
    }
  }

  .container-ourstory {
    font-size: 1.5rem;
    line-height: 1.6;
  }
}

// Sponsors Page Main
.section-header {
  position: relative;
  h1 {
    font-size: 4rem;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
.section-partners {
  position: relative;
  width: 100%;
  height: 100vh;
  background: var(--fifth) url(../../../img/life-carriers.jpg) no-repeat center
    center/cover;

  h1 {
    font-size: 4rem;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 60%;
    height: 2.5rem;
    width: 15rem;
    text-align: center;
    color: #fff;
    border: #fff solid 0.2rem;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    transform: translateX(-50%);
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 2rem;
    }

    .button {
      width: 12rem;
    }
  }

  @media (max-height: 700px) {
    .button {
      top: 70%;
    }
  }
}

.partner-hero {
  padding: calc(122px + 2rem) 3rem 3rem 3rem;
  h1,
  p {
    color: #fff;
    // position: absolute;
    // left: 50%;
    // top: 40%;
    // transform: translateX(-50%);
    // white-space: nowrap;
  }
  h1 {
    font-size: 3.5rem;
  }
}

.split .left .button:hover {
  background-color: var(--left-bg-color);
  border-color: var(--left-button-hover-color);
}

.split .right .button:hover {
  background-color: var(--right-bg-color);
  border-color: var(--right-button-hover-color);
}

.split {
  position: absolute;
  top: 102;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.split.left {
  left: 0;
  // background: url("../../../img/life-carriers.jpeg") center center no-repeat;
  background-size: cover;
}

.split.left:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--left-bg-color);
}

.split.right {
  right: 0;
  // background: url("../../../img/annuity-carriers.jpeg") center center no-repeat;
  background-size: cover;
}

.split.right:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--right-bg-color);
}

.split.left,
.split.right,
.split.left:before,
.split.right:before {
  transition: 1000ms all ease-in-out;
}

.hover-left .left {
  width: 75%;
}

.hover-left .right {
  width: 25%;
}

.hover-left .right:before {
  z-index: 1;
}

.hover-right .right {
  width: 75%;
}

.hover-right .left {
  width: 25%;
}

.hover-right .left:before {
  z-index: 1;
}
.section-partner {
  margin: 0 auto;
  padding: 2rem 3rem;
}
.section-partner h2 {
  text-align: center;
  color: var(--primary);
  font-size: 2rem;
}
.section-partner-photos {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2px;
  width: 100%;

  .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8.75rem;
    width: 17.5rem;
    // background-size: cover;
    // background-position: center center;
    padding: 1rem;
    &:hover {
      border: 2px solid var(--primary);
      border-radius: 1.75rem;
    }
    & img {
      max-width: 100%;
      height: auto;
      transform: scale(0.8);
      transition: transform 250ms ease-in;
      &:hover {
        transform: scale(1);
      }
    }
  }
}

// Our Team
.section-team {
  background: url("../../../img/dmi_header.jpg") no-repeat center 30% / cover;
  height: 27.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-profiles {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}

.profile-wrapper {
  display: flex;
  align-items: center;
  padding: 3rem;
  width: 100%;
  column-gap: 1rem;

  &.reverse {
    flex-direction: row-reverse;
    background: linear-gradient(90deg, var(--fifth), var(--primary));
  }

  img {
    max-width: 40%;
    height: auto;
  }
}

.profile-content {
  // flex: 1 2 auto;
}

.name-wrapper {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-weight: bold;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.5rem;
}

// Sales Training Page
.section-training {
  // width: 100%;
}

// Contact Us Page
.contact-form {
}
form {
  width: 100%;
}
label {
  // display: block;
  margin-bottom: 0.25rem;
}
input[type="text"],
textarea {
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: 100%;
  border: 1px solid (--secondary);

  &:focus {
    outline-color: var(--primary);
  }
}

.option-group {
  margin-bottom: 0.25rem;
}

.red-text {
  color: var(--red);
}
