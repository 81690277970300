@use "../util" as *;

.container {
  padding: 0 rem(24);
  //   max-width: rem(1110);
  margin: 0 auto;

  @include breakpoint(large) {
    padding: 0 rem(48);
    transform: translate(0, 0);
  }
}

.section {
  padding-block-start: 122px;
}
