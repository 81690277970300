:root {
  --primary: rgb(5, 184, 115);
  --title: hsl(147, 56%, 31%);
  --border: hsl(88, 50%, 61%);
  --secondary: hsl(217, 18%, 14%);
  --third: hsl(217, 9%, 52%);
  --forth: hsl(0, 0%, 73%);
  --fifth: hsl(0, 0%, 100%);
  --neutral-200: hsl(0, 0%, 96%);
  --gradient: hsl(141, 20%, 34%);
  --text-primary: hsl(147, 56%, 31%);
  --footer-bg: hsl(216, 23%, 83%);
  // Partner Page
  --container-bg-color: #333;
  --left-bg-color: rgba(5, 184, 115, 0.3);
  --left-button-hover-color: rgba(5, 184, 115, 0.3);
  --right-bg-color: rgba(43, 43, 43, 0.8);
  --right-button-hover-color: rgba(92, 92, 92, 0.3);
  --hover-width: 75%;
  --other-width: 25%;
  --speed: 1000ms;
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);

  // General colors
  --red: #ff0000;
}
