:root {
  --screen-width: 1440px;
}

html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul,
ol,
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  height: 100%;
}

ol.ordered-list,
ul.unordered-list {
  padding-inline-start: 2rem;
  // list-style-image: url("../../../img/obis_clover_logo_transparent.jpg");
}

hr {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
    0.25turn,
    var(--fifth),
    var(--primary),
    var(--fifth)
  );
  border-image-slice: 1;
}

section {
  line-height: 1.6;
  font-size: 1.5rem;
}

.container {
  // max-width: 1280px;
  margin: 0 auto;
  padding: 2rem 3rem;
  // line-height: 1.5;
}

.lead {
  font-size: 2rem;
}
.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 100px;
  color: var(--fifth);
  border: 2px solid var(--border);
  transition: 0.5s;
  &:hover {
    background: var(--fifth);
    color: var(--primary);
    cursor: pointer;
  }
}

.btn-primary {
  background: var(--primary);
  color: var(--fifth);
}

.btn-block {
  display: inline-block;
  margin-bottom: 1rem;
}

.fade-in,
.from-left,
.from-right {
  opacity: 0;
}
.fade-in.appear,
.from-left.appear,
.from-right.appear {
  opacity: 1;
}

.fade-in {
  transition: opacity 250ms ease-in;
}

.from-left {
  transform: translateX(-50%);
}

.from-right {
  transform: translateX(50%);
}

.from-left,
.from-right {
  transition: transform 400ms ease-in;
}

.from-left.appear,
.from-right.appear {
  transform: translateX(0);
}

.home-section {
  padding: calc(122px + 2rem) 3rem 3rem 3rem;
}

.top-section {
  padding: var(--spacer, calc(122px)) 3rem 3rem 3rem;
}

// Flexbox
.flex-items {
  display: flex;
  // flex-wrap: wrap;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}

.column {
  flex-direction: column;
  flex: 1;
}

.flex-reverse {
  flex-direction: row-reverse;
}

// Grid
.grid-items {
  --grid-columns: 3;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
}

.grid-temp-area {
  grid-template-areas:
    "content image"
    "content image";
  grid-template-columns: 50% 1fr;
}
.grid-col {
  text-align: center;

  img {
    max-width: 50%;
  }
}
.name {
  font-size: 2rem;
}

.content {
  font-size: 1.25rem;
  line-height: 1.5;
}

// Spacing
.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 1.5rem 0;
}
.py-3 {
  padding: 2rem 0;
}

.parallax {
  position: relative;
  // opacity: 0.7;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /*
        background-attachment: fixed = parallax
        background-attachment: scroll = normal
    */
  background-attachment: fixed;
}

.parallax {
  background-image: url("../../../img/dmi_header.jpg");
  min-height: 100vh;
  display: block;
}

.heading-1 {
  font-size: 3rem;
}
