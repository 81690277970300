@use "../util" as *;

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

h1,
h2,
h3 {
  margin-top: 0;
}

.text-primary {
  color: var(--text-primary);
}

.text-center {
  text-align: center;
}

.text-light {
  color: var(--fifth);
}

.fw-bold {
  font-weight: 700;
}
