.header {
  position: fixed;
  width: 100%;
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    90deg,
    var(--fifth),
    var(--primary),
    var(--gradient)
  );
  border-bottom: 3px solid var(--border);
  z-index: 10;

  &::before {
    content: "";
    position: absolute;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    background: var(--primary);
  }

  nav {
    & .main-nav {
      // position: relative;
      list-style: none;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      & li {
        display: inline-block;

        &:hover .nav-link__dropdown {
          display: block;
          position: absolute;
          max-width: 30ch;
          list-style: none;
          border-radius: 10px;
          z-index: 100;
        }
      }

      & .nav-link {
        display: block;
        color: var(--fifth);
        padding: 0.75rem 1rem;
        transition: all 100ms cubic-bezier(0.77, 0, 0.175, 1);
        font-size: 1.25rem;
        border: 1px solid transparent;
        text-align: center;

        &.active,
        &:hover {
          background: var(--primary);
          color: var(--fifth);
          border-radius: 100px;
          border: 1px solid var(--border);
          box-shadow: inset -5px -5px 10px rgba(255, 255, 255, 0.2),
            inset 5px 5px 5px rgba(0, 0, 0, 0.1);
        }

        &__dropdown {
          display: none;
          position: absolute;
          min-width: 35ch;
          height: auto;
          border-radius: 0.625rem;
          z-index: 100;
          background: var(--fifth);
          & li {
            display: block;
            margin-block: 0.5rem;
            border-radius: 0.625rem;
          }
          &-link {
            display: block;
            padding: 0.5rem;
            text-align: center;
            color: var(--primary);
            border: 1px transparent solid;
            &:hover {
              background: var(--primary);
              color: var(--fifth);
              border-radius: 100px;
              border: 1px solid var(--border);
              box-shadow: inset -5px -5px 10px rgba(255, 255, 255, 0.2),
                inset 5px 5px 5px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }
}
